<script lang="ts">
  export let classes: string = "h-6 w-6";
</script>

<svg
  class={classes}
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 49.4 512 399.4200000000001"
  ><g fill="none" fill-rule="evenodd"
    ><g fill-rule="nonzero"
      ><path
        d="M34.91 448.818h81.454V251L0 163.727V413.91c0 19.287 15.622 34.91 34.91 34.91z"
        fill="#4285f4"
      /><path
        d="M395.636 448.818h81.455c19.287 0 34.909-15.622 34.909-34.909V163.727L395.636 251z"
        fill="#34a853"
      /><path
        d="M395.636 99.727V251L512 163.727v-46.545c0-43.142-49.25-67.782-83.782-41.891z"
        fill="#fbbc04"
      /></g
    ><path
      d="M116.364 251V99.727L256 204.455 395.636 99.727V251L256 355.727z"
      fill="#ea4335"
    /><path
      d="M0 117.182v46.545L116.364 251V99.727L83.782 75.291C49.25 49.4 0 74.04 0 117.18z"
      fill="#c5221f"
      fill-rule="nonzero"
    /></g
  ></svg
>
