<script lang="ts">
  export let classes: string = "h-6 w-6";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  class={classes}
  viewBox="0 0 500 500"
  version="1.1"
  id="svg01"
>
  <g id="layer1" style="display:inline">
    <path
      d="M 250,155 H 100 c 0,0 -45,0 -45,95 0,95 45,95 45,95 h 45 v -45 c 0,-30 25,-55 55,-55 h 100 c 25,0 45,-20 45,-45 V 100 c 0,0 0,-45 -95,-45 -95,0 -95,45 -95,45 v 45 h 95 z"
      id="path101"
      inkscape:connector-curvature="0"
      style="fill:#3377bb;fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <path
      d="m 250,345 h 150 c 0,0 45,0 45,-95 0,-95 -45,-95 -45,-95 h -45 v 45 c 0,30 -25,55 -55,55 H 200 c -25,0 -45,20 -45,45 v 100 c 0,0 0,45 95,45 95,0 95,-45 95,-45 v -45 h -95 z"
      id="path102"
      inkscape:connector-curvature="0"
      style="fill:#ffbb33;fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <circle
      style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      id="path103"
      cx="200"
      cy="100"
      r="15"
    />
    <circle
      style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:0.5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
      id="path104"
      cx="300"
      cy="400"
      r="15"
    />
  </g>
</svg>
