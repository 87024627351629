import { readable, writable } from "svelte/store";
let dark;
if (window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches) {
    dark = true;
}
export const darkModeStore = writable(dark);
export const menuExpandedStore = writable(false);
export const pageStore = writable("home");
export const projectStore = readable([
    {
        title: "Wethinky",
        description: "Its a blog!",
        images: [
            {
                src: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
                alt: "Laptop",
            },
            {
                src: "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
                alt: "Food",
            },
        ],
    },
    {
        title: "Apollo",
        description: "Its a web application for autonomous stand ups!",
        images: [
            {
                src: "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
                alt: "Food",
            },
            {
                src: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
                alt: "Laptop",
            },
        ],
    },
    {
        title: "Superset",
        description: "A data vizualization platform. I make frequent contributions to Superset and am an active member of Preset.io's community partners program",
        images: [
            {
                src: "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
                alt: "Food",
            },
            {
                src: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
                alt: "Laptop",
            },
        ],
    },
    {
        title: "My Portfolio",
        description: "You're looking at it!",
        images: [
            {
                src: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
                alt: "Laptop",
            },
            {
                src: "https://images.unsplash.com/photo-1540189549336-e6e99c3679fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
                alt: "Food",
            },
        ],
    },
]);
